/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Form,
  Row,
  Col
} from "reactstrap";
// core components
import NikeContent from "./NikeContent.js";


class Nike extends React.Component {
  state = {
    paymentTabs: 1,
  };
  componentDidMount() {
    document.body.classList.add("checkout-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("checkout-page");
  }
  render() {
    return (
      <>
        <div className="section">
          <Row>
            <Col lg="12">
              <Form className="js-validate">
                <h2 className="title">Nike Accounts</h2>
                <NikeContent />
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Nike;
