/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Input,
  Media,
  Table,
  Row,
  Col
} from "reactstrap";
// core components
import Select from "react-select";
import TimeChart from "variables/TimeChart.js";
// Firebase context and Utility
import { withFirebase } from '../../components/Firebase';
import {
  CheckoutModal,
  PageLoader,
  showToastAlert,
  generateRandomString,
  getDate,
  copyData,
  downloadFile
} from '../../components/Utility';
var request = require('request');


class Residential extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      checkoutModals: [],
      userPlans: [],
      plans: [],
      isActivePlan: false,
      authToken: '',
      planStatus: '',
      locations: [],
      continentList: [],
      countryList: [],
      continent: {
        value: "",
        label: "Continent"
      },
      country: {
        value: "",
        label: "Country"
      },
      proxyType: {
        value: "sticky",
        label: "Sticky"
      },
      nbrOfProxy: 255,
      timeCharts: [
        <TimeChart key={Math.random()}/>
      ]
    };
    this.openCheckoutModal = this.openCheckoutModal.bind(this);
    this.onChangeContinent = this.onChangeContinent.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangeProxyType = this.onChangeProxyType.bind(this);
    this.onChangenbrOfProxy = this.onChangenbrOfProxy.bind(this);
    this.showPlanDetails = this.showPlanDetails.bind(this);
    this.generateProxy = this.generateProxy.bind(this);
    this.copyProxies = this.copyProxies.bind(this);
    this.downloadProxies = this.downloadProxies.bind(this);
  }

  openCheckoutModal = (event) => {
    event.stopPropagation();
    try {
      this.setState({
        checkoutModals: []
      });
      let authToken = event.target.closest('button').dataset.authtoken;
      let proxyData = this.state.userPlans.filter(plan => plan.authToken === authToken)[0];
      if (!proxyData) {
        return;
      }
      let showModal = true;
      let checkoutPlanID = proxyData.planID;
      let checkoutPlanType = 'residential';
      let updatePlanRefID = proxyData.updatePlanRefID;
      let checkoutPlanPrice = this.state.plans[checkoutPlanID].price;
      let planPrice = `Plan Price $${checkoutPlanPrice}`;
      this.setState({
        checkoutModals: [
          <CheckoutModal
            showModal={showModal}
            checkoutPlanID={checkoutPlanID}
            updatePlanRefID={updatePlanRefID}
            checkoutPlanType={checkoutPlanType}
            checkoutPlanPrice={checkoutPlanPrice}
            planPrice={planPrice}
            key={authToken}
          />
        ]
      });
    } catch (error) {
      showToastAlert('Error',error.message);
    }
  }

  onChangeContinent = (event) => {
    let countryList = [];
    let countries = this.state.locations.filter(location => location['continent'] === event.value);
    countries.forEach((country) => {
      countryList.push({
        value: country['name'],
        label: country['name']
      });
    });
    let country = countryList[0];

    this.setState({
      countryList: countryList,
      country: country,
      continent: {
        value: event.value,
        label: event.label
      }
    });
  }

  onChangeCountry = (event) => {
    this.setState({
      country: {
        value: event.value,
        label: event.label
      }
    });
  }

  onChangeProxyType = (event) => {
    this.setState({
      proxyType: {
        value: event.value,
        label: event.label
      }
    });
  };

  onChangenbrOfProxy = (event) => {
    this.setState({
      nbrOfProxy: parseInt(event.target.value)
    });
  };

  showPlanDetails = (event, authToken) => {
    try {
      let data = this.state.userPlans.filter(plan => plan.authToken === authToken)[0];
      if (data) {
        document.querySelectorAll('#userPlans tbody tr').forEach((element) => {
          element.classList.remove('active');
        });
        event.target.closest('tr').classList.add('active');
        let dataLimit = data.planData.dataLimitMegaBytes;
        let dataUsed = parseFloat((parseInt(data.planData.dataUsage) / 1000000).toFixed(2));
        let dataRemaining = parseFloat((dataLimit - dataUsed).toFixed(2));
        let chartText = `${dataRemaining} MB Remaining`;
        let chartDataSet = [dataUsed, dataRemaining];
        this.setState({
          authToken: authToken,
          planStatus: data.planData.status,
          timeCharts: [
            <TimeChart
              key={authToken}
              text={chartText}
              dataset={chartDataSet}
            />
          ]
        });
      }
    } catch (error) {
      showToastAlert('Error', error.message);
    }
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });
    let idToken = await this.props.firebase.auth.currentUser.getIdToken(true);
    let options = {
      method: 'POST',
      url: 'http://cloud.jinul.com:4005/api/residential/getProxies',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idToken: idToken
      })
    };

    request(options, (error, response, body) => {
      let resData = body && JSON.parse(body);
      if (error || response.statusCode !== 200) {
        let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching residential plans!';
        this.setState({
          loading: false
        });
        showToastAlert('Error', errorMessage);
      } else {
        if (resData.status === 'success' && Object.keys(resData.data).length) {
          const userPlans = Object.keys(resData.data).map(key => ({
            ...resData.data[key],
            authToken: key,
          }));
          let continentList = [];
          resData.countries.forEach((location) => {
            continentList.push({
              value: location['continent'],
              label: location['continent']
            });
          });
          continentList = continentList.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);
          let continent = continentList[0] || {
            value: "",
            label: "Continent"
          };

          let countryList = [];
          let countries = resData.countries.filter(country => country['continent'] === continent['value']);
          countries.forEach((country) => {
            countryList.push({
              value: country['name'],
              label: country['name']
            });
          });
          let country = countryList[0] || {
            value: "",
            label: "Country"
          };

          this.setState({
            userPlans: userPlans,
            plans: resData.plans,
            isActivePlan: resData.isActivePlan,
            locations: resData.countries,
            continentList: continentList,
            countryList: countryList,
            continent: continent,
            country: country,
            loading: false
          });
          if (this.refs.firstRow) {
            this.refs.firstRow.click();
          }
        } else {
          this.setState({
            loading: false
          });
        }
      }
    });
    document.body.classList.add("checkout-page");
  }

  generateProxy = () => {
    try {
      this.setState({
        loading: true
      });
      let country = this.state.country.value;
      let proxyType = this.state.proxyType.value;
      let nbrOfProxy = parseInt(this.state.nbrOfProxy) || 255;
      let location = this.state.locations.filter(loc => loc.name === country)[0];
      if (!proxyType || !nbrOfProxy || !location) {
        throw new Error('Invalid proxy configurations !');
      }

      let domain = location.domain;
      let portStart = location.portStart;
      let portEnd = location.portEnd;
      let proxyData = this.state.userPlans.filter(plan => plan.authToken === this.state.authToken)[0];
      if (!proxyData || !proxyData.planData) {
        throw new Error('Invalid proxy authToken !');
      }
      let name = proxyData.planData.name;
      let pass = proxyData.planData.pass;
      let proxies = [];

      if (proxyType === 'sticky') {
        for (let i = 1; i <= parseInt(nbrOfProxy); i++) {
          let randomStr = generateRandomString(10);
          let port = Math.floor(Math.random() * (portEnd - portStart + 1) + portStart);
          let proxy = `${domain}:${port}:${name}:${pass}-${randomStr}`;
          proxies.push(proxy);
        }
      } else if (proxyType === 'rotating') {
        let port = Math.floor(Math.random() * (portEnd - portStart + 1) + portStart);
        let proxy = `${domain}:${port}:${name}:${pass}`;
        proxies.push(proxy);
      }
      this.setState({
        loading: false
      });
      return proxies.join('\r\n');
    } catch (error) {
      showToastAlert('Error', error.message);
      this.setState({
        loading: false
      });
      return false;
    }
  }

  copyProxies = () => {
    let proxies = this.generateProxy();
    if (proxies) {
      copyData(proxies);
    }
  }

  downloadProxies = () => {
    let proxies = this.generateProxy();
    if (proxies) {
      let country = this.state.country;
      let proxyType = this.state.proxyType;
      let fileName = `${country.label}_${proxyType.label}.txt`;
      downloadFile(fileName, proxies);
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("checkout-page");
  }

  render() {
    const { loading, checkoutModals, userPlans, plans, isActivePlan, authToken, planStatus, timeCharts, continentList, countryList, continent, country, proxyType, nbrOfProxy } = this.state;

    return (
      <>
        {loading && <PageLoader />}
        <div className="section dashboard-section">
          <Row>
            <Col lg="4" className="mt-3">
              <h2 className="title">Plan summary</h2>
              <Card>
                <CardBody>
                  <Media className="align-items-center">
                    <h3 className="h6 text-secondary mr-3">Authtoken</h3>
                    <Media body className="text-right">
                      <span>{authToken}</span>
                    </Media>
                  </Media>
                  <hr className="line-info mb-4" />

                  {timeCharts.map(timeChart => (
                    timeChart
                  ))}

                  <hr className="line-info mb-3" />
                  <Media className="align-items-center">
                    <h3 className="h6 text-secondary mr-3">Status</h3>
                    <Media body className="text-right">
                      <span className={planStatus === 'active' ? 'text-success' : 'text-error'}>{planStatus === 'active' ? "Active" : "Expired"}</span>
                    </Media>
                  </Media>

                  <hr className="line-info mb-3" />
                  <label>Proxies</label>
                  <hr className="line-info mb-1" />
                  <Media className="align-items-center">
                    <Media body className="text-center">
                      <Button
                        disabled={userPlans.length === 0}
                        id="copyProxies"
                        className="btn-round btn-icon ml-2"
                        size="lg"
                        color="info"
                        onClick={this.copyProxies}
                      >
                        <i className="tim-icons icon-single-copy-04" />
                      </Button>
                      <Button
                        disabled={userPlans.length === 0}
                        id="downloadProxies"
                        className="btn-round btn-icon ml-2"
                        size="lg"
                        color="info"
                        onClick={this.downloadProxies}
                      >
                        <i className="tim-icons icon-cloud-download-93" />
                      </Button>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col lg="8" className="mt-3">
              <Form className="js-validate">
                <h2 className="title">Residential Plans</h2>
                <Row>
                  <Col lg="3" className="mt-3">
                  <Select
                    className="react-select react-select-primary"
                    classNamePrefix="react-select"
                    name="Continent"
                    id="Continent"
                    value={continent}
                    onChange={this.onChangeContinent}
                    options={continentList}
                  />
                  </Col>
                  <Col lg="3" className="mt-3">
                  <Select
                    className="react-select react-select-primary"
                    classNamePrefix="react-select"
                    name="country"
                    id="country"
                    value={country}
                    onChange={this.onChangeCountry}
                    options={countryList}
                  />
                  </Col>
                  <Col lg="3" className="mt-3">
                  <Select
                    className="react-select react-select-primary"
                    classNamePrefix="react-select"
                    name="proxyType"
                    id="proxyType"
                    value={proxyType}
                    onChange={this.onChangeProxyType}
                    options={[
                      { value: "sticky", label: "Sticky" },
                      { value: "rotating", label: "Rotating" }
                    ]}
                  />
                  </Col>
                  <Col lg="3" className="mt-3">
                    <Input
                      type="number"
                      name="nbrOfProxy"
                      id="nbrOfProxy"
                      className="border-primary text-primary"
                      min="1"
                      max="255"
                      value={nbrOfProxy}
                      onChange={this.onChangenbrOfProxy}
                      placeholder="no. Of Proxies"
                    />
                  </Col>
                </Row>
                <Card className="card-plain">
                  <CardHeader>
                    <CardTitle tag="h4" />
                  </CardHeader>
                  <CardBody className="p-0">
                    <Table className="tablesorter" id="userPlans" responsive>
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Token</th>
                          <th className="text-center">Plan</th>
                          <th className="text-center">Start Date</th>
                          <th className="text-center">End Date</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userPlans.map((plan, index) => {
                          let reference = index === 0 ? "firstRow" : "";
                          let authToken = plan.authToken;
                          let dataLimit = `${plan.planData.dataLimitMegaBytes/1000} GB`;
                          let startDate = getDate(new Date(plan.planData.createDate));
                          let endDate = new Date(startDate).getTime() + (plan.planData.validityHours * 3600000);
                          endDate = getDate(new Date(endDate));
                          let isActive = plans[plan.planID].active;
                          return(
                            <tr key={authToken} onClick={(e) => this.showPlanDetails(e, authToken)} ref={reference} data-authtoken={authToken}>
                              <td className="text-center">{index+1}</td>
                              <td className="text-center">
                                {authToken}
                              </td>
                              <td className="text-center">
                                {dataLimit}
                              </td>
                              <td className="text-center">
                                {startDate}
                              </td>
                              <td className="text-center">
                                {endDate}
                              </td>
                              <td className="text-center">
                                <Button
                                  disabled={!isActive || !isActivePlan}
                                  data-authtoken={authToken}
                                  onClick={this.openCheckoutModal}
                                  className="btn-icon btn-simple"
                                  color="info"
                                  size="sm"
                                >
                                  <i className="tim-icons icon-refresh-01" />
                                </Button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
        {checkoutModals.map(checkoutModal => (
          checkoutModal
        ))}
      </>
    );
  }
}

export default withFirebase(Residential);
