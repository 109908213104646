/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";


class Features extends React.Component {
  render() {
    return (
      <>
        <div className="cd-section" id="features">
          {/* ********* FEATURES 1 ********* */}
          <div className="features-1">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h1 className="title">Special Features</h1>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <div className="info info-hover">
                    <div className="icon icon-primary">
                      <img
                        alt="..."
                        className="bg-blob"
                        src={require("assets/img/feature-blob/primary.png")}
                      />
                      <i className="tim-icons icon-user-run" />
                    </div>
                    <h4 className="info-title">Fast and Unbanned on Most Sites</h4>
                    <p className="description">

                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="info info-hover">
                    <div className="icon icon-success">
                      <img
                        alt="..."
                        className="bg-blob"
                        src={require("assets/img/feature-blob/success.png")}
                      />
                      <i className="tim-icons icon-atom" />
                    </div>
                    <h4 className="info-title">99% UPTIME GUARANTEE</h4>
                    <p className="description">

                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="info info-hover">
                    <div className="icon icon-warning">
                      <img
                        alt="..."
                        className="bg-blob"
                        src={require("assets/img/feature-blob/warning.png")}
                      />
                      <i className="tim-icons icon-gift-2" />
                    </div>
                    <h4 className="info-title">Over 1500+ Active Users and Over 10k Proven Success</h4>
                    <p className="description">

                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END FEATURES 1 ********* */}

        </div>{" "}
      </>
    );
  }
}

export default Features;
