/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Row,
  Col
} from "reactstrap";

class Accordion extends React.Component {
  state = {
    collapse: 1
  };
  openCollapse = collapse => {
    this.setState({
      collapse: this.state.collapse === collapse ? -1 : collapse
    });
  };
  render() {
    return (
      <>
        <div className="cd-section" id="accordion">
          <div className="accordion-1">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="6">
                  <h2 className="title mb-4 mt-5">Frequently asked question</h2>
                  <div className="section-space" />
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto" md="12">
                  <div className="accordion">
                    <Card>
                      <CardHeader>
                        <h5 className="mb-0">
                          <Button
                            className="w-100 text-left"
                            color="link"
                            aria-expanded={this.state.collapse === 1}
                            onClick={() => this.openCollapse(1)}
                          >
                            How can i purchase and access to my order?{" "}
                            <i className="tim-icons icon-minimal-down float-right" />
                          </Button>
                        </h5>
                      </CardHeader>
                      <Collapse isOpen={this.state.collapse === 1}>
                        <CardBody>
                          <p>1) Click on the Dashboard Button on the Right Corner.</p>
                          <p>2) Create an account and choose the product that you which to purchase.</p>
                          <p>3) Once Payment it made. Orders will automated deliver to your client portal (Dashboard).</p>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h5 className="mb-0">
                          <Button
                            className="w-100 text-left "
                            color="link"
                            aria-expanded={this.state.collapse === 2}
                            onClick={() => this.openCollapse(2)}
                          >
                            What is the Different between Data Center Proxies and Residential Proxies?{" "}
                            <i className="tim-icons icon-minimal-down float-right" />
                          </Button>
                        </h5>
                      </CardHeader>
                      <Collapse isOpen={this.state.collapse === 2}>
                        <CardBody>
                          <p>- Data Center Proxies provides faster speed compare to Residential Proxies but the chances of getting banned are a lot more higher compare to residential proxies. Residential are known to have slower speed but high stability and works on almost all sites.</p>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h5 className="mb-0">
                          <Button
                            className="w-100 text-left "
                            color="link"
                            aria-expanded={this.state.collapse === 3}
                            onClick={() => this.openCollapse(3)}
                          >
                            What is the different between Un-Farmed Account,V3 Recaptcha Account and Farmed One Clicks Account{" "}
                            <i className="tim-icons icon-minimal-down float-right" />
                          </Button>
                        </h5>
                      </CardHeader>
                      <Collapse isOpen={this.state.collapse === 3}>
                        <CardBody>
                        <p>- Un-Farmed Account means they arent farmed with captcha tools, but they are highly potential to have one clicks when they are farmed on captcha tools with Proxies.</p>
                        <p>- V3 Recaptcha Account means that they are farmed but without one clicks, but it does come with 0.9 Captcha Score.</p>
                        <p>- Farmed One Clicks Account means that they are farmed and come with one clicks ready to bypass captcha.</p>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h5 className="mb-0">
                          <Button
                            className="w-100 text-left"
                            color="link"
                            aria-expanded={this.state.collapse === 4}
                            onClick={() => this.openCollapse(4)}
                          >
                            What is the different between Premium Nike Account and Eceptional Nike Account?{" "}
                            <i className="tim-icons icon-minimal-down float-right" />
                          </Button>
                        </h5>
                      </CardHeader>
                      <Collapse isOpen={this.state.collapse === 4}>
                        <CardBody>
                          <p>- Premium Nike Account doesnt come with warranty from Nike Ban Waves ( Reset Account Passowrd or Unverified ).</p>
                          <p>- Exceptional Nike Account provide free replacements for accounts that get banned/password-reset within 45 Days of your purchase. (We Only Replace Once Per Order).</p>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h5 className="mb-0">
                          <Button
                            className="w-100 text-left"
                            color="link"
                            aria-expanded={this.state.collapse === 5}
                            onClick={() => this.openCollapse(5)}
                          >
                            What will I receive if I rent a bot?{" "}
                            <i className="tim-icons icon-minimal-down float-right" />
                          </Button>
                        </h5>
                      </CardHeader>
                      <Collapse isOpen={this.state.collapse === 5}>
                        <CardBody>
                          <p>- Download link of the bot</p>
                          <p>- Product Key of the bot</p>
                          <p>- Invite to our discord channel for guides and support question.</p>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h5 className="mb-0">
                          <Button
                            className="w-100 text-left"
                            color="link"
                            aria-expanded={this.state.collapse === 6}
                            onClick={() => this.openCollapse(6)}
                          >
                            What Payment Method Do you Accept?{" "}
                            <i className="tim-icons icon-minimal-down float-right" />
                          </Button>
                        </h5>
                      </CardHeader>
                      <Collapse isOpen={this.state.collapse === 6}>
                        <CardBody>
                          <p>- Stripe Only</p>
                        </CardBody>
                      </Collapse>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default Accordion;
