import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
// Firebase context
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';


const INITIAL_STATE = {
  navbarColor: 'navbar-transparent'
};

class ColorNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  changeNavbarColor = () => {
    if (document.documentElement.scrollTop > 299 || document.body.scrollTop > 299) {
      this.setState({
        navbarColor: "bg-default"
      });
    } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      this.setState({
        navbarColor: "navbar-transparent"
      });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.changeNavbarColor);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.changeNavbarColor);
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => {return(
          <Navbar className={"fixed-top " + this.state.navbarColor} expand="lg">
            <Container>
              <div className="navbar-translate">
                <NavbarBrand to="/" tag={Link} id="tooltip6619950104">
                    <img
                      alt="Pookyy"
                      className="logo"
                      width="35px"
                      src={require("assets/img/pookyy.png")}
                    />
                </NavbarBrand>
                <UncontrolledTooltip delay={0} target="tooltip6619950104">
                  Designed and Coded by Jinul
                </UncontrolledTooltip>
                <button className="navbar-toggler" id="navigation">
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>

              <UncontrolledCollapse navbar toggler="#navigation">
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="Pookyy"
                          className="logo"
                          width="35px"
                          src={require("assets/img/pookyy.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close text-right" xs="6">
                      <button className="navbar-toggler" id="navigation">
                        <i className="tim-icons icon-simple-remove" />
                      </button>
                    </Col>
                  </Row>
                </div>

                <Nav className="ml-auto" navbar>
                  {/*
                    <UncontrolledDropdown nav>
                      <DropdownToggle caret color="default" nav>
                        Bot Rental
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem to="/bot-rental" tag={Link}>
                          <i className="tim-icons icon-app" />
                          Ganesh
                        </DropdownItem>
                        <DropdownItem to="/bot-rental" tag={Link}>
                          <i className="tim-icons icon-app" />
                          Sole AIO
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  */}

                  <UncontrolledDropdown nav>
                    <DropdownToggle caret color="default" nav>
                      Proxies
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem to="/datacenter" tag={Link}>
                        <i className="tim-icons icon-app" />
                        Data Center
                      </DropdownItem>
                      <DropdownItem to="/residential" tag={Link}>
                        <i className="tim-icons icon-app" />
                        Residential
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <UncontrolledDropdown nav>
                    <DropdownToggle caret color="default" nav>
                      Accounts
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem to="/gmails" tag={Link}>
                        <i className="tim-icons icon-app" />
                        Gmail Accounts
                      </DropdownItem>
                      <DropdownItem to="/nike-accounts" tag={Link}>
                        <i className="tim-icons icon-app" />
                        Nike Accounts
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  {/*
                    <UncontrolledDropdown nav>
                       <DropdownToggle caret color="default" nav>
                         Tools
                       </DropdownToggle>
                       <DropdownMenu right>
                         <DropdownItem to="/datacenter" tag={Link}>
                           <i className="tim-icons icon-app" />
                           Proxy Tester
                         </DropdownItem>
                       </DropdownMenu>
                     </UncontrolledDropdown>
                  */}

                  <UncontrolledDropdown nav>
                    <DropdownToggle caret color="default" nav>
                    Support
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem to="/contact-us" tag={Link}>
                        <i className="tim-icons icon-square-pin" />
                        Contact Us
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  {authUser ? (
                    <>
                      <NavItem>
                        <Button
                          className="nav-link my-1"
                          color="default"
                          href="/dashboard"
                          size="sm"
                        >
                          Dashboard
                        </Button>
                      </NavItem>
                      <NavItem>
                        <Button
                          onClick={this.props.firebase.doSignOut}
                          className="nav-link my-1"
                          color="default"
                          size="sm"
                        >
                          Sign Out
                        </Button>
                      </NavItem>
                    </>
                  ) : (
                    <NavItem>
                      <Button
                        className="nav-link"
                        color="default"
                        href="/login"
                        size="sm"
                      >
                        Log in
                      </Button>
                    </NavItem>
                  )}
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        )}}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(ColorNavbar);
