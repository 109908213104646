/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Datacenter from "./Datacenter.js";
import Residential from "./Residential.js";
import Gmail from "./Gmail.js";
import Nike from "./Nike.js";
import BotRental from "./BotRental.js";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";

class Projects extends React.Component {
  state = {
    activeTab: "datacenter"
  };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    return (
      <div className="cd-section" id="projects">
        <div className="projects-2 project-raised">
          <Row>
            <Col className="ml-auto mr-auto text-center mb-5" lg="8">
              <h2 className="title">Have a fun!</h2>
              <div className="section-space" />
            </Col>
          </Row>
          <Nav
            className="nav-pills-info nav-pills-icons nav-pills-lg"
            pills
            role="tablist"
          >
            <NavItem className="m-auto">
              <NavLink
                className={this.state.activeTab === "datacenter" ? "active" : ""}
                onClick={() => {
                  this.toggle("datacenter");
                }}
              >
                <i className="tim-icons icon-spaceship" />
                Datacenter
              </NavLink>
            </NavItem>
            <NavItem className="m-auto">
              <NavLink
                className={this.state.activeTab === "residential" ? "active" : ""}
                onClick={() => {
                  this.toggle("residential");
                }}
              >
                <i className="tim-icons icon-bag-16" />
                Residential
              </NavLink>
            </NavItem>
            <NavItem className="m-auto">
              <NavLink
                className={this.state.activeTab === "gmail" ? "active" : ""}
                onClick={() => {
                  this.toggle("gmail");
                }}
              >
                <i className="tim-icons icon-light-3" />
                Gmail Accounts
              </NavLink>
            </NavItem>
            <NavItem className="m-auto">
              <NavLink
                className={this.state.activeTab === "nike" ? "active" : ""}
                onClick={() => {
                  this.toggle("nike");
                }}
              >
                <i className="tim-icons icon-molecule-40" />
                Nike Accounts
              </NavLink>
            </NavItem>
            <NavItem className="mx-auto">
              <NavLink
                className={this.state.activeTab === "bot" ? "active" : ""}
                onClick={() => {
                  this.toggle("bot");
                }}
              >
                <i className="tim-icons icon-planet" />
                Bot Rental
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent className="tab-space" activeTab={this.state.activeTab}>
            <TabPane tabId="datacenter">
              <div className="wrapper" ref="wrapper">
                <Datacenter />
              </div>
            </TabPane>
            <TabPane tabId="residential">
              <div className="wrapper" ref="wrapper">
                <Residential />
              </div>
            </TabPane>
            <TabPane tabId="gmail">
              <div className="wrapper" ref="wrapper">
                <Gmail />
              </div>
            </TabPane>
            <TabPane tabId="nike">
              <div className="wrapper" ref="wrapper">
                <Nike />
              </div>
            </TabPane>
            <TabPane tabId="bot">
              <div className="wrapper" ref="wrapper">
                <BotRental />
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

export default Projects;
