/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

class Pricing extends React.Component {
  state = {
    firstTabs: "1",
    secondTabs: "2",
    thirdTabs: "1"
  };
  toggle = (tabName, tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        [tabName]: tab
      });
    }
  };
  render() {
    return (
      <>
        <div className="cd-section" id="pricing">

        {/* ********* PRICING 1 ********* */}
        <div className="pricing-1" id="pricing-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">Pick the best plan for you</h2>
                <h4 className="description">
                  You have Free Unlimited Updates and Premium Support on each
                  package.
                </h4>
                <div className="section-space" />
              </Col>
            </Row>
            <div className="space-100" />
            <Row>
              <Nav
                className="nav-tabs-primary ml-auto mb-4"
                role="tablist"
                tabs
              >
                <NavItem>
                  <NavLink
                    className={this.state.firstTabs === "1" ? "active" : ""}
                    onClick={() => {
                      this.toggle("firstTabs", "1");
                    }}
                  >
                    Monthly
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.firstTabs === "2" ? "active" : ""}
                    onClick={() => {
                      this.toggle("firstTabs", "2");
                    }}
                  >
                    Yearly
                  </NavLink>
                </NavItem>
              </Nav>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-pricing">
                  <CardHeader className="bg-info">
                    <Badge className="badge-default">Unwarmed</Badge>
                    <CardTitle tag="h1">
                      <small>$</small>
                      9.00
                    </CardTitle>
                    <h5>per month</h5>
                  </CardHeader>
                  <CardBody>
                    <ul>
                      <li>
                        <i className="tim-icons icon-check-2" /> Year Made 2014 - 2018
                      </li>
                      <li>
                        <i className="tim-icons icon-email-85" /> Generated Human Activity
                      </li>
                      <li>
                        <i className="tim-icons icon-chart-bar-32" /> High Trusted Gmails
                      </li>
                      <li>
                        <i className="tim-icons icon-alert-circle-exc" /> Registered using Unique US Proxies IPs
                      </li>
                    </ul>
                    <Button
                      disabled
                      className="mt-4"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      Get started
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-pricing">
                  <CardHeader className="bg-danger">
                    <Badge className="badge-default">Warmed</Badge>
                    <CardTitle tag="h1">
                      <small>$</small>
                      20.00
                    </CardTitle>
                    <h5>per month</h5>
                  </CardHeader>
                  <CardBody>
                    <ul>
                      <li>
                        <i className="tim-icons icon-check-2" /> Format : Email : Password : RecoveryEmail : Proxies
                      </li>
                      <li>
                        <i className="tim-icons icon-email-85" /> Farmed with activity
                      </li>
                      <li>
                        <i className="tim-icons icon-chart-bar-32" /> Instant Delivery
                      </li>
                      <li>
                        <i className="tim-icons icon-alert-circle-exc" /> Active Support
                      </li>
                    </ul>
                    <Button
                      disabled
                      className="mt-4"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      Get started
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-pricing">
                  <CardHeader className="bg-warning">
                    <Badge className="badge-default">Raffle</Badge>
                    <CardTitle tag="h1">
                      <small>$</small>
                      170.00
                    </CardTitle>
                    <h5>per month</h5>
                  </CardHeader>
                  <CardBody>
                    <ul>
                      <li>
                        <i className="tim-icons icon-check-2" />Aged Gmail Created(2014-2018)
                      </li>
                      <li>
                        <i className="tim-icons icon-email-85" />1Main Gmail Forwarded to 100Gmail
                      </li>
                      <li>
                        <i className="tim-icons icon-chart-bar-32" />Registered using Unique Proxies
                      </li>
                      <li>
                        <i className="tim-icons icon-alert-circle-exc" />Spam Filtered
                      </li>
                    </ul>
                    <Button
                      disabled
                      className="mt-4"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      Get started
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END PRICING 1 ********* */}
        </div>
      </>
    );
  }
}

export default Pricing;
