/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";


class Pricing extends React.Component {
  render() {
    return (
      <>
        <div className="section section-pricing" id="sectionBuy">
          <Container>
            <Row id="pricing">
              <Col className="ml-auto mr-auto text-center mt-5" md="8">
                <h2 className="title">
                  Prepare yourself with the best proxies!
                </h2>
              </Col>

              <Col className="ml-auto mr-auto" md="8">
                <p className="description mb-5 text-center">
                </p>
                <Row>
                  <Col className="pt-lg-5" md="6">
                    <Card className="card-pricing card-plain card-primary">
                      <CardBody>
                        <h4 className="title">Data Center</h4>
                        <CardTitle tag="h1">
                          <small>$</small>
                          140
                        </CardTitle>
                        <ul>
                          <li>
                            <b>Super</b> Fast
                          </li>
                          <li>
                            <b>Unlimited</b> Bandwidth
                          </li>
                          <li>
                            <b>10</b>+ GBPS
                          </li>
                          <li>
                            <b>100</b> Proxies
                          </li>
                          <li>
                            IP <b>Authentication</b>
                          </li>
                        </ul>
                        <Button
                          disabled
                          className="btn-round"
                          color="info"
                          href="/datacenter"
                        >
                          Buy Now!
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col className="pt-lg-5" md="6">
                    <Card className="card-pricing card-plain card-primary">
                      <CardBody>
                        <h4 className="title">Residential</h4>
                        <CardTitle tag="h1">
                          <small>$</small>
                          20
                        </CardTitle>
                        <ul className="b-left">
                          <li>Pure Residential</li>
                          <li>
                            You can Create <b>Sticky</b> <b>and</b>{" "}
                             <b>Rotating</b> Proxies
                          </li>
                          <li>
                            <b>140+ Countries</b>
                          </li>
                          <li>
                            <b>45 Days</b> Expiry
                          </li>
                          <li>
                            <b>Lowest</b> price <b>renewal</b>
                          </li>
                        </ul>
                        <Button
                          className="btn-round"
                          color="info"
                          href="residential"
                        >
                          Buy Now!
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div
              className="section section-download"
              data-background-color="black"
              id="#download-section"
            >
              <Container>
                <br />
                <br />
                <br />
                <Row className="row-grid align-items-center my-md">
                  <Col lg="6">
                    <h3 className="text-primary font-weight-light mb-2">
                      Thank you for supporting us!
                    </h3>
                    <h4 className="mb-0 font-weight-light">
                      Let's get in touch on any of these platforms.
                    </h4>
                  </Col>
                  <Col className="text-lg-center btn-wrapper" lg="6">
                    <Button
                      href="https://twitter.com/PookyyAIO"
                      target="_blank"
                      className="btn-icon btn-round"
                      color="twitter"
                      id="twitter"
                      size="lg"
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="twitter">
                      Tweet!
                    </UncontrolledTooltip>

                    <Button
                      href="https://discord.gg/bjNb6Ft"
                      target="_blank"
                      className="btn-icon btn-round ml-1"
                      color="discord"
                      id="discord"
                      size="lg"
                    >
                      <i className="fab fa-discord" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="discord">
                      Join!
                    </UncontrolledTooltip>
                  </Col>
                </Row>
              </Container>
            </div>
          </Container>
        </div>{" "}
      </>
    );
  }
}

export default Pricing;
