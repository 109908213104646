import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, withRouter } from "react-router-dom";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

// page components
import Home from "views/Home.js";
import Datacenter from "views/Datacenter.js";
import Residential from "views/Residential.js";
import Gmail from "views/Gmail.js";
import Nike from "views/Nike.js";
// import BotRental from "views/BotRental.js";
// import BotRentalViewBot from "views/BotRentalViewBot.js";
import Dashboard from "views/Dashboard.js";

import LoginPage from "views/examples/LoginPage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import ForgotPasswordPage from "views/examples/ForgotPasswordPage.js";
import ContactUs from "views/examples/ContactUs.js";
import TOSPage from "views/examples/TOSPage.js";
import PrivacyPolicy from "views/examples/PrivacyPolicy.js";
import FAQPage from "views/examples/FAQPage.js";
import SuccessPage from "views/examples/SuccessPage.js";
import ErrorPage from "views/examples/ErrorPage.js";
import Error404 from "views/examples/Error404.js";
import Error500 from "views/examples/Error500.js";
// Session context
import { withAuthentication } from 'components/Session';


class App extends Component {
  render() {
    const Main = withRouter(({location}) => {
      return(
        <>
          <ColorNavbar/>
          <Switch>
            <Route exact path="/" render={props => <Home {...props} />} />
            <Route path="/login" render={props => <LoginPage {...props} />} />
            <Route path="/register" render={props => <RegisterPage {...props} />} />
            <Route path="/forgot-password" render={props => <ForgotPasswordPage {...props} />} />
            <Route path="/datacenter" render={props => <Datacenter {...props} />} />
            <Route path="/residential" render={props => <Residential {...props} />} />
            <Route path="/gmails" render={props => <Gmail {...props} />} />
            <Route path="/nike-accounts" render={props => <Nike {...props} />} />
            {/*
              <Route exact path="/bot-rental" render={props => <BotRental {...props} />} />
              <Route path="/bot-rental/:id" render={props => <BotRentalViewBot {...props} />} />
            */}
            <Route path="/dashboard" render={props => <Dashboard {...props} />} />

            <Route path="/contact-us" render={props => <ContactUs {...props} />} />
            <Route path="/terms-of-service" render={props => <TOSPage {...props} />} />
            <Route path="/privacy-policy" render={props => <PrivacyPolicy {...props} />} />
            <Route path="/faq" render={props => <FAQPage {...props} />} />

            <Route path="/success" render={props => <SuccessPage {...props} />} />
            <Route path="/error" render={props => <ErrorPage {...props} />} />
            <Route path="/404" render={props => <Error404 {...props} />} />
            <Route path="/500" render={props => <Error500 {...props} />} />
            <Redirect from='*' to='/404' />
          </Switch>
          {
            location.pathname !== '/dashboard' &&
            location.pathname !== '/login' &&
            location.pathname !== '/register' &&
            location.pathname !== '/forgot-password' &&
            location.pathname !== '/success' &&
            location.pathname !== '/error' &&
            location.pathname !== '/404' &&
            location.pathname !== '/500' &&
            <DemoFooter />
          }
        </>
      )
    });

    return (
      <Router>
        <Main />
      </Router>
    );
  }
}

export default withAuthentication(App);
