/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";


class DemoFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer">
          <Container >
            <Row>
              <Col md="2">
              </Col>

              <Col md="4" xs="6">
                <Nav>

                  <NavItem>
                    <NavLink to="/datacenter" tag={Link}>
                      Data Center
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink to="/residential" tag={Link}>
                      Residential
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink to="/gmails" tag={Link}>
                      Gmail Accounts
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink to="/nike-accounts" tag={Link}>
                      Nike Accounts
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>

              <Col md="3" xs="6">
                <Nav>

                  <NavItem>
                    <NavLink to="/contact-us" tag={Link}>
                      Contact Us
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink to="/terms-of-service" tag={Link}>
                      Terms Of Service
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink to="/privacy-Policy" tag={Link}>
                      Privacy Policy
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink to="/faq" tag={Link}>
                      FAQ
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>

              <Col md="3">
                <h3 className="title">Follow us:</h3>
                <div className="btn-wrapper profile text-left">
                  <Button
                    className="btn-icon btn-neutral btn-round btn-simple"
                    color="default"
                    href="https://twitter.com/PookyyAIO"
                    id="tooltip39661217"
                    target="_blank"
                  >
                    <i className="fab fa-twitter" />
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip39661217">
                    Follow us
                  </UncontrolledTooltip>

                  <Button
                    className="btn-icon btn-neutral btn-round btn-simple ml-1"
                    color="default"
                    href="https://discord.gg/bjNb6Ft"
                    id="tooltip206037619"
                    target="_blank"
                  >
                    <i className="fab fa-discord" />
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip206037619">
                    Join us
                  </UncontrolledTooltip>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default DemoFooter;
