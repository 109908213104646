import React from "react";
import ReactDOM from "react-dom";

// styles
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.1.0";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "toastr/build/toastr.min.css";
import "assets/css/custom.css";

import App from 'App';
import Firebase, { FirebaseContext } from 'components/Firebase';

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
