/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";


class Features extends React.Component {
  render() {
    return (
      <>
        <div className="cd-section" id="features">
          {/* ********* FEATURES 2 ********* */}
          <div className="features-2">
            <Container>
              <Row className="align-items-center">
                <Col className="mr-auto text-left" lg="3" md="8">
                  <h1 className="title">Features</h1>
                  <p className="description">

                  </p>
                  <Button
                    className="btn-simple"
                    color="info"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    Learn more <i className="tim-icons icon-alert-circle-exc" />
                  </Button>
                </Col>
                <Col lg="8" md="12">
                  <Row>
                    <Col lg="4" md="4">
                      <div className="info text-left bg-info">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-laptop" />
                        </div>
                        <h4 className="info-title">Client Portal</h4>
                        <p className="description">
                          Client could easily manage their proxies, to check the quantity proxies, Data Remaining, Proxies Expiry Date, and Generate unlimited Proxies with our Residential Proxies.
                        </p>
                      </div>
                    </Col>
                    <Col lg="4" md="4">
                      <div className="info text-left bg-danger info-raised">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-spaceship" />
                        </div>
                        <h4 className="info-title">99% UPTIME GUARANTEE</h4>
                        <p className="description">
                          We are providing not only the highest quality proxies, but also the best 10GBPS server to ensure the proxies are working even there are high traffic.
                        </p>
                      </div>
                    </Col>
                    <Col lg="4" md="4">
                      <div className="info text-left bg-default">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-user-run" />
                        </div>
                        <h4 className="info-title">Fast Speed and High Stability</h4>
                        <p className="description">
                          We provide the fastest speed and the best server to host the proxies to ensure the there are NO downtime even though there are heavy traffic during release.
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4" md="4">
                      <div className="info text-left bg-primary">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-support-17" />
                        </div>
                        <h4 className="info-title">Friendly Support Team</h4>
                        <p className="description">
                          Customer Satisfaction is our main concern. Our discord server do have several professional support teams that are from different regions to provide the fastest solution for our beloved client.
                        </p>
                      </div>
                    </Col>
                    <Col lg="4" md="4">
                      <div className="info text-left bg-warning info-raised">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-cart" />
                        </div>
                        <h4 className="info-title">Price Friendly</h4>
                        <p className="description">
                          We're providing the best quality for the customer with the best pricing in the market.
                        </p>
                      </div>
                    </Col>
                    <Col lg="4" md="4">
                      <div className="info text-left bg-success">
                        <div className="icon icon-default">
                          <i className="tim-icons icon-key-25" />
                        </div>
                        <h4 className="info-title text-muted">
                          Private Proxies
                        </h4>
                        <p className="description text-muted">
                          Data Proxies that we providing are dedicated which isn't IP Proxies that are shared around.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END FEATURES 2 ********* */}

        </div>{" "}
      </>
    );
  }
}

export default Features;
