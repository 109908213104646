/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";


class FAQ extends React.Component {
  render() {
    return (
      <>
        <div className="cd-section" id="faqs">

          {/* ********* FEATURES 2 ********* */}
          <div className="features-2">
            <Container>
              <Row className="align-items-center">
                <Col className="mr-auto text-left">

                  <h2>How can i purchase and access to my order?</h2>
                  <div className="description">
                    1) Click on the Dashboard Button on the Right Corner.
                    <br />
                    2) Create an account and choose the product that you which to purchase.
                    <br />
                    3) Once Payment it made. Orders will automated deliver to your client portal (Dashboard).
                    <br />
                  </div>

                  <h2>What is the Different between Data Center Proxies and Residential Proxies?</h2>
                  <div className="description">
                    - Data Center Proxies provides faster speed compare to Residential Proxies but the chances of getting banned are a lot more higher compare to residential proxies. Residential are known to have slower speed but high stability and works on almost all sites.
                  </div>

                  <h2>What is the different between Un-Farmed Account,V3 Recaptcha Account and Farmed One Clicks Account</h2>
                  <div className="description">
                    - Un-Farmed Account means they arent farmed with captcha tools, but they are highly potential to have one clicks when they are farmed on captcha tools with Proxies.
                    <br />
                    - V3 Recaptcha Account means that they are farmed but without one clicks, but it does come with 0.9 Captcha Score.
                    <br />
                    - Farmed One Clicks Account means that they are farmed and come with one clicks ready to bypass captcha.
                  </div>

                  <h2>What is the different between Premium Nike Account and Eceptional Nike Account?</h2>
                  <div className="description">
                    - Premium Nike Account doesnt come with warranty from Nike Ban Waves ( Reset Account Passowrd or Unverified ).
                    <br />
                    - Exceptional Nike Account provide free replacements for accounts that get banned/password-reset within 45 Days of your purchase. (We Only Replace Once Per Order).
                  </div>

                  <h2>What will I receive if I rent a bot?</h2>
                  <div className="description">
                    - Download link of the bot
                    <br />
                    - Product Key of the bot
                    <br />
                    - Invite to our discord channel for guides and support question.
                  </div>

                  <h2>What Payment Method Do you Accept?</h2>
                  <div className="description">
                    - Stripe Only
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END FEATURES 2 ********* */}

        </div>{" "}
      </>
    );
  }
}

export default FAQ;
