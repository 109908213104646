import React from "react";
// react plugin used to create DropdownMenu for selecting items
import { Doughnut, Chart} from 'react-chartjs-2';


class TimeChart extends React.Component {
  constructor(props) {
    super(props);
    let text = props.text || '0 Hours Remaining';
    let dataset = props.dataset || [100, 0];

    let data = {
      labels: [
        'Used',
        'Remaining',
      ],
      config: [{
        cutoutPercentage: 0
      }],
      text: text,
      datasets: [{
        data: dataset,
        backgroundColor: [
          '#1D8BF7',
          '#171941'
        ],
        hoverBackgroundColor: [
          '#1d49f7',
          '#080929'
        ],
        borderWidth: 0
      }]
    };

    let donutOptions = {
      responsive: true,
      cutoutPercentage: 90,
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontSize: 12,
          fontColor: '#B0BAB1',
          padding: 30
        }
      }
    };

    this.state = {
      data,
      donutOptions
    };
  }

  render() {
    const { data, donutOptions } = this.state;

    var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
    Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
      draw: function() {
        originalDoughnutDraw.apply(this, arguments);

        var chart = this.chart.chart;
        var ctx = chart.ctx;
        var width = chart.width;
        // var height = chart.height;

        var fontSize = 1;
        ctx.font = fontSize + "em Poppins";
        ctx.textBaseline = "middle";
        ctx.color = 'white';

        var text = chart.config.data.text,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = 95;
        ctx.fillText(text, textX, textY);
      }
    });

    return (
      <Doughnut
        options={donutOptions}
        data={data}
        width={200}
      />
    );
  }
}

export default TimeChart;
