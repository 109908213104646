/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";


class TOS extends React.Component {
  render() {
    return (
      <>
        <div className="cd-section" id="terms-and-conditions">

          {/* ********* FEATURES 2 ********* */}
          <div className="features-2">
            <Container>
              <Row className="align-items-center">
                <Col className="mr-auto text-left">
                  <h1 className="title">Terms and Conditions</h1>

                  <h2>Introduction</h2>
                  <div className="description">
                    These Website Standard Terms and Conditions written on this webpage shall manage your use of our website,Pookyy Account, Pookyy Nike Accounts, PookyyGmails, Pookyy Rental and Pookyy Proxies accessible at <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a>
                    <br />
                    These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.
                    <br />
                    Pookyy Account does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> only with the permission of a parent or guardian.
                  </div>

                  <h2>Refund Policy</h2>
                  <div className="description">
                    <p>YOU FULLY UNDERSTAND:</p>
                    <br />
                    These proxies are active from the time you receive them. All proxies are tested before delivery and are fully working when delivered. Proxies are designated as data center or residential based on the product name and description, and are not decided by the customer. We are not responsible for bans during release for improper use and having an incorrect setup. We reserve the right to deactivate proxies with excessive bandwidth usage or usage outside of its intended purposes such as video streaming, or file sharing. Please do only use our proxies on the websites that pertain to the purpose of the product. By purchasing this item, you are agreeing to our TOS "No Returns" policy due to the nature of the product. All products sold on <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> are virtual and cannot be returned.
                    <br />
                    Gmails Accounts are active from the time you receive them. All Gmail Accounts are tested before delivery and are fully working when delivered. Gmails Accounts are designated as unwarmed Gmails,Warmed Gmails and Raffle Gmails based on the product name and description, and are not decided by the customer. We are not responsible for bans ,changed password after delivery or problem in changing passcode as it might be cause by buyers IP issue, after the purchase date.Besides, Strictly No refund or replacement customer claims there are no one clicks for warmed gmail,We've tested several times to ensure the product is as description. Therefore we will not exchange or refund for warmed gmails as it might be abused by the buyer. By purchasing this item, you are agreeing to our TOS "No Returns" policy due to the nature of the product. All products sold on <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> are virtual and cannot be returned.
                    <br />
                    Nike Accounts are active from the time you receive them. All Nike Accounts are tested before delivery and are fully working when delivered. Nike Accounts are designated as Premium US/UK Nike Accounts and Exceptional US/UK Accounts based on the product name and description, and are not decided by the customer. We are not responsible for bans,changed password/Reset Password or Unverified for Deluxe US/UK Nike Accounts.We Are providing 42 days Warranty on Exceptional US/UK Nike Accounts for Only Password Reset and Unverified. Both Premium and Exceptional US/UK Nike Accounts are not responsible for Banned Accounts.for By purchasing this item, you are agreeing to our TOS "No Returns" policy due to the nature of the product. All products sold on <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> are virtual and cannot be returned.
                  </div>

                  <h2>Acceptable Use Policy</h2>
                  <div className="description">
                    You are prohibited from using the site, its content and services: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses without prior notice.
                  </div>

                  <h2>Intellectual Property Rights</h2>
                  <div className="description">
                    Other than the content you own, under these Terms, Pookyy Account and/or its licensors own all the intellectual property rights and materials contained in this Website.
                    <br />
                    You are granted limited license only for purposes of viewing the material contained on this Website.
                  </div>

                  <h2>Restrictions</h2>
                  <div className="description">
                    You are specifically restricted from all of the following:
                    <br/>
                    <span className="ml-3">- publishing any <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> material in any other media;</span>
                    <br />
                    <span className="ml-3">- selling, sublicensing and/or otherwise commercializing any Website material;</span>
                    <br />
                    <span className="ml-3">- publicly performing and/or showing any Website material;</span>
                    <br />
                    <span className="ml-3">- using this <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> in any way that is or may be damaging to this Website;</span>
                    <br />
                    <span className="ml-3">- using this <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> in any way that impacts user access to this Website;</span>
                    <br />
                    <span className="ml-3">- using this <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</span>
                    <br />
                    <span className="ml-3">- engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;</span>
                    <br />
                    <span className="ml-3">- using this Website to engage in any advertising or marketing.</span>
                    <br />
                    Certain areas of this Website are restricted from being access by you and Peachy Proxies may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.
                  </div>

                  <h2>Your Content</h2>
                  <div className="description">
                    In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video text, images or other material you choose to display on this Website. By displaying Your Content, you grant <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.
                    <br />
                    Your Content must be your own and must not be invading any third-party's rights. <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> reserves the right to remove any of Your Content from this Website at any time without prior notice.
                  </div>

                  <h2>No Warranties</h2>
                  <div className="description">
                    This Website is provided “as is,” with all faults, and <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> express no representations or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.
                  </div>

                  <h2>Limitation of liability</h2>
                  <div className="description">
                    In no event shall <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a>, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract. <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.
                  </div>

                  <h2>Indemnification</h2>
                  <div className="description">
                    You hereby indemnify to the fullest extent <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.
                  </div>

                  <h2>Severabillity</h2>
                  <div className="description">
                    If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
                  </div>

                  <h2>Variation of Terms</h2>
                  <div className="description">
                    <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.
                  </div>

                  <h2>Assignment</h2>
                  <div className="description">
                    <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
                  </div>

                  <h2>Entire Agreement</h2>
                  <div className="description">
                    These Terms constitute the entire agreement between <a href="https://pookyyaccount.com/">https://pookyyaccount.com/</a> and you in relation to your use of this Website, and supersede all prior agreements and understandings.
                  </div>

                  <h2>Governing Law & Jurisdiction</h2>
                  <div className="description">
                    These Terms will be governed by and interpreted in accordance with the laws of Malaysia, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Malaysia for the resolution of any disputes.
                    <br />
                    <br />
                    <h5 className="text-danger mt-3">Pookyy Rental Terms of Service.</h5>
                    <h5 className="text-danger mt-3">Do we offer refund?</h5>
                    <strong>Answer : NO. We will not issue any refund no matter what is the reason is it. All sales are final. We do not issue refunds for digital products once the order is confirmed. You agree that any money spent on our service is non-refundable.</strong>
                    <h5 className="text-danger mt-3">Pre-orders Rental ( Do we issue a refund if the rental is not started yet ? )</h5>
                    <strong>Answer : NO. Once payment are made, all sales are final. We will not issue refunds for digital products once the order is confirmed. You agree that any money spent on our service is non-refundable.</strong>
                    <h5 className="text-danger mt-3">When will Pre-orders Rental date started at?</h5>
                    <strong>Answer : Pre-orders Rental date will be started from the date stated on the variant of the product. (Key will be sent instant on the date stated on the product variant).</strong>
                    <h5 className="text-danger mt-3">What timezone shall we follow for the rental period?</h5>
                    <strong>Answer : We will follow according EST Time Zone. Key will be unbound after 28 days at 00:00 sharp.</strong>
                    <h5 className="text-danger mt-3">User Guidelines for using Pookyy Rental Services. </h5>
                    <strong>
                      Please Read Carefully and Follow the guides on using Pookyy Rental Services.
                      <br />
                      <span className="ml-3">1. Once Customer successfully received the Key(Software Key), Customer have to download the software and activate them with the Key that we sent out within 3 hours and Notify Pookyy Rental Service via Discord or Twitter (https://twitter.com/PookyyRental) if there are any activation errors .</span>
                      <br />
                      <span className="ml-3">2. If we didn't receive any response from our customer whether there are any activation error within 3 hours the key is sent, we will consider it as successfully activated.</span>
                      <br />
                      <span className="ml-3">3. After Key is successfully activated, It is not our issue for any personal error or technical error (such as software error,server error) that causing unable to activate the rented out software.</span>
                      <br />
                      <span className="ml-3">4. After key is successfully activated, it is Customer fault for not resetting the key after using. Customer should reset or deactivate the keys after finish using them on their computer or server.</span>
                      <br />
                      <span className="ml-3">5. Additional request upon reset key is not covered in Pookyy Rental.</span>
                    </strong>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END FEATURES 2 ********* */}

        </div>{" "}
      </>
    );
  }
}

export default TOS;
