/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";


class Pricing extends React.Component {
  state = {
    firstTabs: "1",
    secondTabs: "2",
    thirdTabs: "1"
  };
  toggle = (tabName, tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        [tabName]: tab
      });
    }
  };
  render() {
    return (
      <>
        <div className="cd-section" id="pricing">
        {/* ********* PRICING 5 ********* */}
        <div className="pricing-5" id="pricing-5">
          <div className="space-50" />
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">Pick the best plan for you</h2>
                <h4 className="description">
                  You have Free Unlimited Updates and Premium Support on each
                  package.
                </h4>
                <div className="section-space" />
              </Col>
            </Row>
            <div className="space-50" />
            <Row className="justify-content-center">
              <Col md="5">
                <Card className="card-pricing card-plain card-primary">
                  <CardBody>
                    <h2 className="title">
                      Premium US Nike Accounts
                    </h2>
                    <h5 className="description">
                      $20.00 <small>/Monthly</small>
                    </h5>
                    <ul>
                      <li>Private and Premium Real US Sims Verified</li>
                      <li>Works on Every SNKRS/Nike Store</li>
                      <li>No Warranty For Accounts</li>
                      <li>Special Pricing for repurchase (0.8$ Each) as a Special Warranty if Account getting banned by Nike Within 45 Days</li>
                      <li>We Do Not Re-use Numbers</li>
                      <li>Instant Delivery</li>
                    </ul>
                    <Button
                      disabled
                      className="btn-round"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      Choose plan
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="5">
                <Card className="card-pricing card-plain card-primary">
                  <CardBody>
                    <h2 className="title">Exceptional US Nike Account <Badge color="warning">Best choice</Badge></h2>
                    <h5 className="description">
                      $22.00 <small>/Monthly</small>
                    </h5>
                    <ul>
                      <li>Private and Premium Real US Sims Verified</li>
                      <li>Works on Every SNKRS/Nike Store</li>
                      <li>60 Days Guarantee for Unverified Account</li>
                      <li>Replacements for accounts that get banned/password-reset within 45 Days of your purchase</li>
                      <li>Instant Delivery</li>
                    </ul>
                    <Button
                      disabled
                      className="btn-round"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      Choose plan
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END PRICING 5 ********* */}
        </div>
      </>
    );
  }
}

export default Pricing;
