/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Container
} from "reactstrap";


class Headers extends React.Component {
  state = {};
  render() {
    return (
      <>
          {/* ********* HEADER 1 ********* */}
          <div className="page-header page-header-small">
            <img
              alt="..."
              className="path shape"
              width="700px"
              src={require("assets/img/shape-s.png")}
            />
            <Container>
              <h1 className="h1-seo">FAQs</h1>
            </Container>
          </div>
      </>
    );
  }
}

export default Headers;
