/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";


class SuccessPage extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("error-page");
  }

  componentWillUnmount() {
    document.body.classList.remove("error-page");
  }

  render() {
    return (
      <div className="wrapper" ref="wrapper">
        <div className="page-header error-page header-filter stripe-page">
          <div className="page-header-image"/>
          <Container>
            <Row>
              <Col md="12">
                <h1 className="title">We have received your order! :)</h1>
                <h4 className="description">
                  Thank you for your order! Orders are fulfilled instantly. Please check your dashboard!
                </h4>
                <Button
                  className="mt-3 animation-on-hover"
                  color="default"
                  href="/dashboard"
                  size="lg"
                >
                  Dashboard
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default SuccessPage;
