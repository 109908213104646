/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroup,
  Media,
  Row,
  Col
} from "reactstrap";
// core components
import DatacenterContent from "./DatacenterContent.js";
import TimeChart from "variables/TimeChart.js";
// import TagsInput from "react-tagsinput";


class Datacenter extends React.Component {
  state = {
    paymentTabs: 1,
    tags: ["192.168.0.1", "10.0.0.1", "24.120.11.2", "172.16.16.16"]
  };
  handleTags = regularTags => {
    this.setState({ tags: regularTags });
  };
  componentDidMount() {
    document.body.classList.add("checkout-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("checkout-page");
  }
  render() {
    return (
      <>
        <div className="section">
            <Row>
              <Col lg="4">
                <h2 className="title">Plan summary</h2>
                <Card>
                  <CardBody>
                    <Media className="align-items-center">
                      <h3 className="h6 text-secondary mr-3">Authtoken</h3>
                      <Media body className="text-right">
                        <span>-</span>
                      </Media>
                    </Media>
                    <hr className="line-info mb-4" />

                    <TimeChart />
                    <hr className="line-info mb-3" />
                    <Form className="code-validate">
                      <label>My IP</label>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": this.state.discountFocus
                        })}
                      >
                        <Input
                          readOnly
                          aria-label="Discount"
                          name="name"
                          placeholder="192.168.1.1"
                          type="text"
                          value=""
                          onFocus={e =>
                            this.setState({ discountFocus: true })
                          }
                          onBlur={e =>
                            this.setState({ discountFocus: false })
                          }
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="info" type="button">
                            Authenticate
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </Form>
                    <hr className="line-info mb-3" />
                    <Media className="align-items-center">
                      <h3 className="h6 text-secondary mr-3">Location</h3>
                      <Media body className="text-right">
                        <span>-</span>
                      </Media>
                    </Media>
                    <Media className="align-items-center">
                      <h3 className="h6 text-secondary mr-3">Status</h3>
                      <Media body className="text-right">
                        <span>-</span>
                      </Media>
                    </Media>
                    <hr className="line-info mb-3" />
                      <label>Proxies</label>
                    <hr className="line-info mb-1" />
                    <Media className="align-items-center">
                      <Media body className="text-center">
                        <Button disabled className="btn-round btn-icon" style={{marginRight: '10px'}} size="lg" color="info">
                          <i className="tim-icons icon-single-copy-04" />
                        </Button>
                        <Button disabled className="btn-round btn-icon" style={{marginLeft: '10px'}} size="lg" color="info">
                          <i className="tim-icons icon-cloud-download-93" />
                        </Button>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="8">
                <Form className="js-validate">
                  <h2 className="title">Datacenter Plans</h2>
                  <DatacenterContent />
                  {/*
                    <div className="title">
                      <h4>Authenticated IPs:</h4>
                    </div>
                    <TagsInput
                      tagProps={{ className: "react-tagsinput-tag bg-danger" }}
                      value={this.state.tags}
                      onChange={this.handleTags}
                    />
                  */}
                </Form>
              </Col>
            </Row>
        </div>
      </>
    );
  }
}

export default Datacenter;
