/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table
} from "reactstrap";


class NikeContent extends React.Component {
  render() {
    return (
      <>
        <Card className="card-plain">
          <CardHeader>
            <CardTitle tag="h4" />
          </CardHeader>
          <CardBody>
            <Table className="tablesorter" responsive>
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center">Token</th>
                  <th className="text-center">Plan</th>
                  <th className="text-center">Start Date</th>
                  <th className="text-center">End Date</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="6">You don't have any active plan</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default NikeContent;
