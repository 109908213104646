import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyCbOlLcjW1y9rzZ13m0q9hoa4SQZ8yO7IQ",
  authDomain: "pookyy-accounts.firebaseapp.com",
  databaseURL: "https://pookyy-accounts.firebaseio.com",
  projectId: "pookyy-accounts",
  storageBucket: "pookyy-accounts.appspot.com",
  messagingSenderId: "329088924661",
  appId: "1:329088924661:web:c4ffff5cd8299c79b73ebc"
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.db = app.database();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);
}

export default Firebase;
