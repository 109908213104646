/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col
} from "reactstrap";
// Firebase context
import { withFirebase } from '../../components/Firebase';
import { PageLoader } from '../../components/Utility';
import { showToastAlert } from '../../components/Utility';


const INITIAL_STATE = {
  loading: true,
  email: '',
};

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const {
      email
    } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({
          ...INITIAL_STATE,
          loading: false
        });
        showToastAlert('Success', 'Password reset link has been sent to your email address!');
        this.props.history.push('/login');
      })
      .catch(error => {
        showToastAlert('Error', error.message);
      });
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  async componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged(user => {
      if (user) {
        this.props.history.push('/');
      } else {
        this.setState({
          loading: false
        });
      }
    });
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("reset-page");
  }

  componentWillUnmount() {
    document.body.classList.remove("reset-page");
  }

  render() {
    const { loading, email } = this.state;
    const isInvalid = email === '';

    return (
      <>
        {loading && <PageLoader />}
        <div className="wrapper" ref="wrapper">
          <div className="page-header">
            <div className="squares square1" />
            <div className="squares square2" />
            <div className="squares square3" />
            <div className="squares square4" />
            <div className="squares square5" />
            <div className="squares square6" />
            <div className="page-header-image" />
            <Container>
              <Col className="mx-auto" lg="5" md="8">
                <Card className="card-login">
                  <Form action="" className="form" method="">
                    <CardHeader>
                      <CardImg
                        alt="..."
                        src={require("assets/img/shape.png")}
                      />
                      <CardTitle tag="h4">Forgot Password</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <h4 className="description text-center">
                        Enter email address to reset password
                      </h4>
                      <InputGroup
                        className={classnames("input-lg", {
                          "input-group-focus": this.state.emailFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="email"
                          type="email"
                          value={email}
                          onChange={this.onChange}
                          placeholder="Email"
                          onFocus={e => this.setState({ emailFocus: true })}
                          onBlur={e => this.setState({ emailFocus: false })}
                          required
                        />
                      </InputGroup>
                      <Button
                        block
                        disabled={isInvalid}
                        className="btn-round"
                        color="warning"
                        onClick={this.onSubmit}
                        size="lg"
                      >
                        Reset Password
                      </Button>
                    </CardBody>
                    <CardFooter>
                      <div className="mb-3 d-block text-center">
                        <p>
                          Don't have an account?{' '}
                          <Link to="/register" className="link footer-link">
                            Sign Up
                          </Link>
                        </p>
                      </div>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default compose(withRouter,withFirebase)(ForgotPasswordPage);
