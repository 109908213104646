import React from "react";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import BasicComponents from "views/HomeSections/BasicComponents.js"
import Pricing from "views/HomeSections/Pricing.js"


class Home extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("landing-page");
  }

  componentWillUnmount() {
    document.body.classList.remove("landing-page");
  }

  render() {
    return (
      <>
        <div className="wrapper" ref="wrapper">
          <div className="page-header">
            <img
              alt="..."
              className="path"
              src={require("assets/img/blob.png")}
            />
            <img
              alt="..."
              className="path2"
              src={require("assets/img/path2.png")}
            />
            <img
              alt="..."
              className="shapes triangle"
              src={require("assets/img/triunghiuri.png")}
            />
            <img
              alt="..."
              className="shapes circle"
              src={require("assets/img/cercuri.png")}
            />
            <Container>
              <Row className="row-grid justify-content-between align-items-center text-left">
                <Col lg="6" md="6">
                  <h1 className="text-white">
                    PookyyAIO<br />
                  </h1>
                  <p className="text-white mb-3">
                  You All In One Needs to maximize your chances on copping limit sneaker. We have been over years providing the highest quality and different product range for our clients.
                  </p>
                  <div className="btn-wrapper mb-3">
                    <p className="category text-success d-inline">
                      From $19.99/mo
                    </p>
                    <Button
                      className="btn-link"
                      color="success"
                      href="/datacenter"
                      size="sm"
                    >
                      <i className="tim-icons icon-minimal-right" />
                    </Button>
                  </div>
                  <div className="btn-wrapper">
                    <div className="button-container">
                      <Button
                        className="btn-icon btn-simple btn-round mr-1"
                        color="neutral"
                        href="https://twitter.com/PookyyAIO"
                        target="_blank"
                      >
                        <i className="fab fa-twitter" />
                      </Button>
                      <Button
                        className="btn-icon btn-simple btn-round"
                        color="neutral"
                        href="https://discord.gg/bjNb6Ft"
                        target="_blank"
                      >
                        <i className="fab fa-discord" />
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col lg="4" md="5">

                </Col>
              </Row>
            </Container>
          </div>
          <section className="section section-lg">
          <section className="section">
              <img
                alt="..."
                className="path"
                src={require("assets/img/path4.png")}
              />
              <Container>
                <Row className="row-grid justify-content-between">
                  <Col className="mt-lg-5" md="5">
                    <Row>
                      <Col className="px-2 py-2" lg="6" sm="12">
                        <Card className="card-stats">
                          <CardBody>
                            <Row>
                              <Col md="4" xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="tim-icons icon-trophy text-warning" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="numbers">
                                  <CardTitle tag="p">3,237</CardTitle>
                                  <p />
                                  <p className="card-category">Awards</p>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col className="px-2 py-2" lg="6" sm="12">
                        <Card className="card-stats upper bg-default">
                          <CardBody>
                            <Row>
                              <Col md="4" xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="tim-icons icon-coins text-white" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="numbers">
                                  <CardTitle tag="p">3,653</CardTitle>
                                  <p />
                                  <p className="card-category">Plans sold</p>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="px-2 py-2" lg="6" sm="12">
                        <Card className="card-stats">
                          <CardBody>
                            <Row>
                              <Col md="4" xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="tim-icons icon-gift-2 text-info" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="numbers">
                                  <CardTitle tag="p">593</CardTitle>
                                  <p />
                                  <p className="card-category">Give Aways</p>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col className="px-2 py-2" lg="6" sm="12">
                        <Card className="card-stats">
                          <CardBody>
                            <Row>
                              <Col md="4" xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="tim-icons icon-credit-card text-success" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="numbers">
                                  <CardTitle tag="p">10,783</CardTitle>
                                  <p />
                                  <p className="card-category">Checkouts</p>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <div className="pl-md-5">
                      <h1>
                        Large <br />
                        Achivements
                      </h1>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="section">
              <img
                alt="..."
                className="path"
                src={require("assets/img/path4.png")}
              />
              <Container>
              <div className="features-1 features-home-page">
                <Container>
                  <Row>
                    <Col className="ml-auto mr-auto" md="8">
                      <Badge color="warning">Our Products</Badge>
                      <h1 className="title">Destroying the competition</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <div className="info info-hover">
                        <div className="icon icon-primary">
                          <img
                            alt="..."
                            className="bg-blob"
                            src={require("assets/img/feature-blob/primary.png")}
                          />
                          <i className="tim-icons icon-user-run" />
                        </div>
                        <h4 className="info-title">99% Guarantee Uptime</h4>
                        <p className="description">

                        </p>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="info info-hover">
                        <div className="icon icon-success">
                          <img
                            alt="..."
                            className="bg-blob"
                            src={require("assets/img/feature-blob/success.png")}
                          />
                          <i className="tim-icons icon-atom" />
                        </div>
                        <h4 className="info-title">Fast and Realiabe</h4>
                        <p className="description">

                        </p>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="info info-hover">
                        <div className="icon icon-warning">
                          <img
                            alt="..."
                            className="bg-blob"
                            src={require("assets/img/feature-blob/warning.png")}
                          />
                          <i className="tim-icons icon-gift-2" />
                        </div>
                        <h4 className="info-title">24/7 Professional Support Team</h4>
                        <p className="description">

                        </p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

              </Container>
            </section>
          </section>
          <section className="section section-lg">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png")}
            />
            <img
              alt="..."
              className="path2"
              src={require("assets/img/path5.png")}
            />
            <img
              alt="..."
              className="path3"
              src={require("assets/img/path2.png")}
            />

          </section>
          <section className="section section-lg section-safe">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path5.png")}
            />
            <Container>
              <Row className="row-grid justify-content-between">
                <Col md="5">

                  <Card className="card-stats bg-danger">
                    <CardBody>
                      <div className="justify-content-center">
                        <div className="numbers">
                          <CardTitle tag="p">99.9%</CardTitle>
                          <p className="card-category text-white">Uptime</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="card-stats bg-info">
                    <CardBody>
                      <div className="justify-content-center">
                        <div className="numbers">
                          <CardTitle tag="p">50 K</CardTitle>
                          <p className="card-category text-white">
                            Successful Checkouts
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="card-stats bg-default">
                    <CardBody>
                      <div className="justify-content-center">
                        <div className="numbers">
                          <CardTitle tag="p">21827</CardTitle>
                          <p className="card-category text-white">Active Users</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="px-md-5">
                    <hr className="line-success" />
                    <h3>Features</h3>
                    <p>

                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-vector" />
                          </div>
                          <div className="ml-3">
                            <h6>Private Residential Proxies Pool</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-cart" />
                          </div>
                          <div className="ml-3">
                            <h6>Flexible and Price Friendly Proxies Plan</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-spaceship" />
                          </div>
                          <div className="ml-3">
                            <h6>Lighting Fast Speed</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-cloud-download-93" />
                          </div>
                          <div className="ml-3">
                            <h6>10 GBPS Data Center Proxies</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-components" />
                          </div>
                          <div className="ml-3">
                            <h6>Brand New Dashboard Design</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-support-17" />
                          </div>
                          <div className="ml-3">
                            <h6>Friendly Support Team</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-satisfied" />
                          </div>
                          <div className="ml-3">
                            <h6>High Success Rate</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <BasicComponents />
          <div className="presentation-page">
            <Pricing />
          </div>
        </div>
      </>
    );
  }
}

export default Home;
