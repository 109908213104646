import React from "react";
// Sections for this page
// (we've divided this page into multiple react components to make it a bit more readable)
import Headers from "../PrivacyPolicySections/Headers.js";
import Policy from "../PrivacyPolicySections/Policy.js";


class PrivacyPolicy extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    var href = window.location.href.substring(window.location.href.lastIndexOf("#") + 1);
    if (window.location.href.lastIndexOf("#") > 0 && document.getElementById(href) !== null) {
      document.getElementById(href).scrollIntoView();
    }
    document.body.classList.add("sections-page");
  }

  componentWillUnmount() {
    document.body.classList.remove("sections-page");
  }

  render() {
    return (
      <div className="wrapper" ref="wrapper">
        <div className="section-space" />
        <Headers />
        <Policy />
      </div>
    );
  }
}

export default PrivacyPolicy;
