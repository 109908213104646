/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col
} from "reactstrap";
// Firebase context
import { withFirebase } from '../../components/Firebase';
import { PageLoader } from '../../components/Utility';
import { showToastAlert } from '../../components/Utility';


const INITIAL_STATE = {
  loading: true,
  email: '',
  password: ''
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const {
      email,
      password
    } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(authUser => {
        this.setState({
          ...INITIAL_STATE,
          loading: false
        });
        this.props.history.push('/');
      })
      .catch(error => {
        showToastAlert('Error', error.message);
      });
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  async componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged(user => {
      if (user) {
        this.props.history.push('/');
      } else {
        this.setState({
          loading: false
        });
      }
    });
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    document.body.classList.add("login-page");
  }

  componentWillUnmount() {
    document.body.classList.remove("login-page");
  }

  render() {
    const { loading, email, password } = this.state;
    const isInvalid = password === '' || email === '';

    return (
      <>
        {loading && <PageLoader />}
        <div className="page-header">
          <div className="squares square1" />
          <div className="squares square2" />
          <div className="squares square3" />
          <div className="squares square4" />
          <div className="squares square5" />
          <div className="squares square6" />
          <div className="page-header-image" />

          <Container>
            <Col className="mx-auto" lg="5" md="8">
              <Card className="card-login">
                <Form className="form" onSubmit={this.onSubmit}>
                  <CardHeader>
                    <CardImg
                      alt="..."
                      src={require("assets/img/square-purple-1.png")}
                    />
                    <CardTitle tag="h4" className="text-uppercase">Login</CardTitle>
                  </CardHeader>

                  <CardBody>
                    <InputGroup
                      className={classnames("input-lg", {
                        "input-group-focus": this.state.emailFocus
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="email"
                        type="email"
                        value={email}
                        onChange={this.onChange}
                        placeholder="Email"
                        onFocus={e => this.setState({ emailFocus: true })}
                        onBlur={e => this.setState({ emailFocus: false })}
                        required
                      />
                    </InputGroup>

                    <InputGroup
                      className={classnames("input-lg", {
                        "input-group-focus": this.state.passwordFocus
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="password"
                        type="password"
                        value={password}
                        onChange={this.onChange}
                        placeholder="Password"
                        onFocus={e =>this.setState({ passwordFocus: true })}
                        onBlur={e =>this.setState({ passwordFocus: false })}
                        required
                      />
                    </InputGroup>
                    <div>
                      <Link to="/forgot-password" className="link footer-link">
                        Forgot Password?
                      </Link>
                    </div>
                  </CardBody>

                  <CardFooter className="text-center">
                    <Button
                      block
                      disabled={isInvalid}
                      className="btn-round"
                      color="primary"
                      onClick={this.onSubmit}
                      size="lg"
                    >
                      Get Started
                    </Button>
                  </CardFooter>

                  <div className="mb-3 text-center">
                    <p>
                      Don't have an account?{' '}
                      <Link to="/register" className="link footer-link">
                        Sign Up
                      </Link>
                    </p>
                  </div>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

export default compose(withRouter,withFirebase)(LoginPage);
