/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";


class Policy extends React.Component {
  render() {
    return (
      <>
        <div className="cd-section" id="privacy-policies">
          <div className="features-2">
            <Container>
              <Row className="align-items-center">
                <Col className="mr-auto text-left">
                  <div className="description">
                    Pookyy SDN BHD ("us", "we", or "our") operates the <a href="https://pookyyaccount.com">https://pookyyaccount.com</a> website (hereinafter referred to as the "Service").
                  </div>
                  <div className="description">
                    This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.
                  </div>
                  <div className="description">
                    We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from <a href="https://pookyyaccount.com">https://pookyyaccount.com</a>
                  </div>
                </Col>
              </Row>

              <Row className="align-items-center">
                <Col className="mr-auto text-left">
                  <h1 className="title">Definitions</h1>

                  <h2>Service</h2>
                  <div className="description">
                    Service is the <a href="https://pookyyaccount.com">https://pookyyaccount.com</a> website operated by Pookyy SDN BHD
                  </div>

                  <h2>Personal Data</h2>
                  <div className="description">
                    Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
                  </div>

                  <h2>Usage Data</h2>
                  <div className="description">
                    Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                  </div>

                  <h2>Cookies</h2>
                  <div className="description">
                    Cookies are small files stored on your device (computer or mobile device).
                  </div>

                  <h2>Data Controller</h2>
                  <div className="description">
                    Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.
                    <br/>
                    We may use the services of various Service Providers in order to process your data more effectively.
                  </div>

                  <h2>Data Subject (or User)</h2>
                  <div className="description">
                    Data Subject is any living individual who is using our Service and is the subject of Personal Data.
                  </div>

                  <h2>Information Collection and Use</h2>
                  <div className="description">
                    We collect several different types of information for various purposes to provide and improve our Service to you.
                    <br/>
                    Types of Data Collected
                    <br/>
                    <span className="ml-3">- Personal Data</span>
                    <br/>
                    While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
                    <br/>
                    <span className="ml-3">- Email address</span>
                    <br/>
                    <span className="ml-3">- Cookies and Usage Data</span>
                    <br/>
                    <span className="ml-3">- Usage Data</span>
                    <br/>
                    We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                  </div>

                  <h2>Tracking & Cookies Data</h2>
                  <div className="description">
                    We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
                    <br/>
                    Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyse our Service.
                    <br/>
                    You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
                    <br/>
                    Examples of Cookies we use:
                    <br/>
                    <span className="ml-3">- Session Cookies. We use Session Cookies to operate our Service.</span>
                    <br/>
                    <span className="ml-3">- Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</span>
                    <br/>
                    <span className="ml-3">- Security Cookies. We use Security Cookies for security purposes.</span>
                    <br/>
                  </div>

                  <h2>Use of Data</h2>
                  <div className="description">
                    Pookyy SDN BHD uses the collected data for various purposes:
                    <br/>
                    <span className="ml-3">- To provide and maintain our Service</span>
                    <br/>
                    <span className="ml-3">- To notify you about changes to our Service</span>
                    <br />
                    <span className="ml-3">- To allow you to participate in interactive features of our Service when you choose to do so</span>
                    <br />
                    <span className="ml-3">- To provide customer support</span>
                    <br />
                    <span className="ml-3">- To gather analysis or valuable information so that we can improve our Service</span>
                    <br />
                    <span className="ml-3">- To monitor the usage of our Service</span>
                    <br />
                    <span className="ml-3">- To detect, prevent and address technical issues</span>
                    <br />
                    <span className="ml-3">- Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)</span>
                    <br />
                    <span className="ml-3">- If you are from the European Economic Area (EEA), Pookyy SDN BHD legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</span>
                    <br />
                    <br />

                    Pookyy SDN BHD may process your Personal Data because:
                    <br />
                    <span className="ml-3">- We need to perform a contract with you</span>
                    <br />
                    <span className="ml-3">- You have given us permission to do so</span>
                    <br />
                    <span className="ml-3">- The processing is in our legitimate interests and it is not overridden by your rights</span>
                    <br />
                    <span className="ml-3">- For payment processing purposes</span>
                    <br />
                    <span className="ml-3">- To comply with the law</span>
                    <br />
                    <span className="ml-3">- Retention of Data</span>
                    <br />
                    <br />

                    Pookyy SDN BHD will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.
                    <br />
                    <br />

                    Pookyy SDN BHD will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.
                    <br />
                  </div>

                  <h2>Transfer of Data</h2>
                  <div className="description">
                    Your information, including Personal Data, may be transferred to � and maintained on � computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
                    <br />
                    If you are located outside Malaysia and choose to provide information to us, please note that we transfer the data, including Personal Data, to Malaysia and process it there.
                    <br />
                    Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                    <br />
                    Pookyy SDN BHD will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.
                    <br />
                  </div>

                  <h2>Disclosure of Data & Business Transaction</h2>
                  <div className="description">
                    If Pookyy SDN BHD is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.
                  </div>

                  <h2>Disclosure for Law Enforcement</h2>
                  <div className="description">
                    Under certain circumstances, Pookyy SDN BHD may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
                  </div>

                  <h2>Legal Requirements</h2>
                  <div className="description">
                    Pookyy SDN BHD may disclose your Personal Data in the good faith belief that such action is necessary to:
                    <br />
                    <span className="ml-3">- To comply with a legal obligation</span>
                    <br />
                    <span className="ml-3">- To protect and defend the rights or property of Pookyy SDN BHD</span>
                    <br />
                    <span className="ml-3">- To prevent or investigate possible wrongdoing in connection with the Service</span>
                    <br />
                    <span className="ml-3">- To protect the personal safety of users of the Service or the public</span>
                    <br />
                    <span className="ml-3">- To protect against legal liability</span>
                    <br />
                  </div>

                  <h2>Security of Data</h2>
                  <div className="description">
                    The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                  </div>

                  <h2>Service Providers</h2>
                  <div className="description">
                    We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.
                    <br />
                    These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                  </div>

                  <h2>Analytics</h2>
                  <div className="description">
                    We may use third-party Service Providers to monitor and analyse the use of our Service.
                    <br />
                    Google Analytics
                    <br />
                    <span className="ml-3">- Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.</span>
                    <br />
                    <span className="ml-3">- You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.</span>
                    <br />
                    <span className="ml-3">- For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy?hl=en" rel="noopener noreferrer" target="_blank">https://policies.google.com/privacy?hl=en</a></span>
                    <br />
                  </div>

                  <h2>Payments</h2>
                  <div className="description">
                    We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).
                    <br />
                    We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
                    <br />
                    The payment processors we work with are:
                    <br />
                    <span className="ml-3">- Stripe</span>
                    <br />
                    <span className="ml-4">Their Privacy Policy can be viewed at <a href="https://stripe.com/us/privacy" rel="noopener noreferrer" target="_blank">https://stripe.com/us/privacy</a></span>
                  </div>

                  <h2>Links to Other Sites</h2>
                  <div className="description">
                    Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
                    <br />
                    We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
                  </div>

                  <h2>Children's Privacy</h2>
                  <div className="description">
                    Our Service does not address anyone under the age of 18 ("Children").
                    <br />
                    We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
                  </div>

                  <h2>Changes to This Privacy Policy</h2>
                  <div className="description">
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                    <br />
                    We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.
                    <br />
                    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                  </div>

                  <h2>Contact Us</h2>
                  <div className="description">
                    If you have any questions about this Privacy Policy, please contact us at <a href="mailto:admin@pookyy.io">admin@pookyy.io</a>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>{" "}
      </>
    );
  }
}

export default Policy;
