/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";


class Headers extends React.Component {
  state = {};
  render() {
    return (
      <>
      {/* ********* HEADER 5 ********* */}
      <div className="header header-5 nike-header">
        <div className="page-header">
          <Container>
            <Row className="align-items-center mt-md-5">
              <Col className="ml-auto mr-auto" lg="6" md="8">
                <div className="logo">
                  <div className="logo-image">
                    <img alt="..." src={require("assets/img/nike.png")} />
                  </div>
                </div>
                <h2 className="title text-left">
                  Our Nike Accounts are to be used to increase the success rate on entering SNKRS Draw. Each of them are created in a unique way to bypass detection and verified with a private sim.
                </h2>
              </Col>
              <Col className="ml-auto text-center" lg="4">
                <div className="phone-container">
                  <img
                    alt="..."
                    className="phone"
                    src={require("assets/img/nike-gotem.png")}
                  />
                </div>
                <img
                  alt="..."
                  className="shape"
                  src={require("assets/img/patrat.png")}
                />
                <img
                  alt="..."
                  className="shape2"
                  src={require("assets/img/cercuri.png")}
                />
                <img
                  alt="..."
                  className="shape3"
                  src={require("assets/img/waves.png")}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {/* ********* END HEADER 5 ********* */}
      </>
    );
  }
}

export default Headers;
