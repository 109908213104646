import React from "react";
// Sections for this page
import Headers from "./ResidentialSections/Headers.js";
import Features from "./ResidentialSections/Features.js";
import Pricing from "./ResidentialSections/Pricing.js";
import Accordion from "./ResidentialSections/Accordion.js";
import Subscribe from "./ResidentialSections/SubscribeEmail.js";
// Firebase context and Utility
import { withFirebase } from '../components/Firebase';
import { PageLoader } from '../components/Utility';


class Residential extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isActivePlan: false,
      plans: []
    };
  }

  componentDidMount() {
    // setState loading: true for pageLoader
    this.setState({ loading: true });

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    var href = window.location.href.substring(window.location.href.lastIndexOf("#") + 1);
    if (window.location.href.lastIndexOf("#") > 0 && document.getElementById(href) !== null) {
      document.getElementById(href).scrollIntoView();
    }
    document.body.classList.add("sections-page");

    //Fetch and Display Residential Plans from Firebase
    this.props.firebase.db.ref('plans/residential').on('value', snapshot => {
      let plansObject = snapshot.val();
      this.setState({
        isActivePlan: plansObject.active
      });
      delete plansObject.active;

      const plansList = Object.keys(plansObject).map(key => ({
        ...plansObject[key],
        planID: key,
      }));
      this.setState({
        plans: plansList,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.db.ref('plans/residential').off();
    document.body.classList.remove("sections-page");
  }

  render() {
    const { plans, loading, isActivePlan } = this.state;

    return (
      <>
        {loading && <PageLoader />}
        <div className="wrapper" ref="wrapper">
          <div className="section-space" />
          <Headers />
          <Features />
          <Pricing plans={plans} isActivePlan={isActivePlan} firebase={this.props.firebase}/>
          <Accordion />
          <Subscribe />
        </div>
      </>
    );
  }
}

export default withFirebase(Residential);
