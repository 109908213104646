/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// Firebase context
import { withFirebase } from '../../components/Firebase';
import { PageLoader } from '../../components/Utility';
import { showToastAlert } from '../../components/Utility';


const INITIAL_STATE = {
  loading: true,
  username: '',
  email: '',
  password: '',
  tos: false,
  squares1to6: '',
  squares7and8: ''
};

class RegisterPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const {
      username,
      email,
      password
    } = this.state;
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then(authUser => {
        return authUser.user.updateProfile({
          displayName: username
        });
      }).then(() => {
        if (this._isMounted) {
          this.setState({
            ...INITIAL_STATE,
            loading: false
          });
        }
        this.props.history.push('/');
      }).catch(error => {
        showToastAlert('Error', error.message);
      });
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.type === 'checkbox' ?  event.target.checked : event.target.value
    });
  };

  async componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged(user => {
      if (user) {
        this.props.history.push('/');
      } else {
        this.setState({
          loading: false
        });
      }
    });
    this._isMounted = true;
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("register-page");
    document.documentElement.addEventListener("mousemove", this.followCursor);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.body.classList.remove("register-page");
    document.documentElement.removeEventListener(
      "mousemove",
      this.followCursor
    );
  }

  followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    this.setState({
      squares1to6: "perspective(500px) rotateY(" + posX * 0.05 + "deg) rotateX(" + posY * -0.05 + "deg)",
      squares7and8: "perspective(500px) rotateY(" + posX * 0.02 + "deg) rotateX(" + posY * -0.02 + "deg)"
    });
  };

  render() {
    const { loading, username, email, password, tos } = this.state;
    const isInvalid = password === '' || email === '' || username === '' || tos === false;

    return (
      <>
        {loading && <PageLoader />}
        <div className="wrapper" ref="wrapper">
          <div className="page-header">
            <div className="page-header-image" />
            <Container>
              <Row>
                <Col className="mx-auto" lg="5" md="12">
                  <div
                    className="square square-7"
                    id="square7"
                    style={{ transform: this.state.squares7and8 }}
                  />
                  <div
                    className="square square-8"
                    id="square8"
                    style={{ transform: this.state.squares7and8 }}
                  />

                  <Card className="card-register">
                    <CardHeader>
                      <CardImg
                        alt="..."
                        src={require("assets/img/square1.png")}
                      />
                      <CardTitle tag="h4" className="text-uppercase">Register</CardTitle>
                    </CardHeader>

                    <CardBody>
                      <Form className="form" onSubmit={this.onSubmit}>
                        <InputGroup
                          className={classnames({
                            "input-group-focus": this.state.usernameFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name="username"
                            type="text"
                            value={username}
                            onChange={this.onChange}
                            placeholder="Full Name"
                            onFocus={e => this.setState({ usernameFocus: true })}
                            onBlur={e => this.setState({ usernameFocus: false })}
                            required
                          />
                        </InputGroup>

                        <InputGroup
                          className={classnames({
                            "input-group-focus": this.state.emailFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name="email"
                            type="email"
                            value={email}
                            onChange={this.onChange}
                            placeholder="Email"
                            onFocus={e => this.setState({ emailFocus: true })}
                            onBlur={e => this.setState({ emailFocus: false })}
                            required
                          />
                        </InputGroup>

                        <InputGroup
                          className={classnames({
                            "input-group-focus": this.state.passwordFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name="password"
                            type="password"
                            value={password}
                            onChange={this.onChange}
                            placeholder="Password"
                            onFocus={e =>this.setState({ passwordFocus: true })}
                            onBlur={e =>this.setState({ passwordFocus: false })}
                            required
                          />
                        </InputGroup>

                        <FormGroup check className="text-left">
                          <Label check>
                            <Input
                              name="tos"
                              type="checkbox"
                              checked={tos}
                              onChange={this.onChange}
                              required
                            />
                            <span className="form-check-sign" />I agree to the{" "}
                            <Link to="/terms-of-service">
                              terms and conditions
                            </Link>
                          </Label>
                        </FormGroup>
                      </Form>
                    </CardBody>

                    <CardFooter>
                      <Button
                        block
                        disabled={isInvalid}
                        className="btn-round"
                        color="info"
                        onClick={this.onSubmit}
                        size="lg"
                      >
                        Get Started
                      </Button>
                    </CardFooter>

                    <div className="mb-3 text-center">
                      <p>Already have an account?{" "}
                        <Link to="/login" className="footer-link">
                           Log in
                        </Link>
                      </p>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>

            <div className="register-bg" />
            <div
              className="square square-1"
              id="square1"
              style={{ transform: this.state.squares1to6 }}
            />
            <div
              className="square square-2"
              id="square2"
              style={{ transform: this.state.squares1to6 }}
            />
            <div
              className="square square-3"
              id="square3"
              style={{ transform: this.state.squares1to6 }}
            />
            <div
              className="square square-4"
              id="square4"
              style={{ transform: this.state.squares1to6 }}
            />
            <div
              className="square square-5"
              id="square5"
              style={{ transform: this.state.squares1to6 }}
            />
            <div
              className="square square-6"
              id="square6"
              style={{ transform: this.state.squares1to6 }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default compose(withRouter,withFirebase)(RegisterPage);
