/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";
import { CheckoutModal } from '../../components/Utility';
import { showToastAlert } from '../../components/Utility';


class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thirdTabs: "1",
      checkoutModals: []
    };
    this.openCheckoutModal = this.openCheckoutModal.bind(this);
  }

  openCheckoutModal = (event) => {
    try {
      this.setState({
        checkoutModals: []
      });
      let showModal = true;
      let checkoutPlanID = event.target.dataset.planid;
      let checkoutPlanType = 'residential';
      let checkoutPlan = this.props.plans.filter(plan => plan.planID === checkoutPlanID)[0];
      if (!checkoutPlan) {
        return;
      }
      if (!this.props.firebase.auth.currentUser) {
        showToastAlert('Error', 'Log in required to purchase a plan');
        return;
      }
      let checkoutPlanPrice = checkoutPlan['price'];
      let planPrice = `Plan Price $${checkoutPlanPrice}`;
      this.setState({
        checkoutModals: [
          <CheckoutModal
            showModal={showModal}
            checkoutPlanID={checkoutPlanID}
            checkoutPlanType={checkoutPlanType}
            checkoutPlanPrice={checkoutPlanPrice}
            planPrice={planPrice}
            key={Math.random()}
          />
        ]
      });
    } catch (error) {
      showToastAlert('Error',error.message);
    }
  }

  toggle = (tabName, tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        [tabName]: tab
      });
    }
  }

  render() {
    const { plans, isActivePlan } = this.props;
    const { checkoutModals } = this.state;

    return (
      <div className="cd-section" id="pricing">
        <div className="pricing-6 section-image" id="pricing-6">
          <Container>
            <Row>
              <Col md="4">
                <h2 className="title">Choose a plan for your next drop</h2>
                <Nav className="nav-pills-primary" pills role="tablist">
                  <NavItem>
                    <NavLink
                      className={this.state.thirdTabs === "1" ? "active" : ""}
                      onClick={() => {
                        this.toggle("thirdTabs", "1");
                      }}
                    >
                      Pro
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.thirdTabs === "2" ? "active" : ""}
                      onClick={() => {
                        this.toggle("thirdTabs", "2");
                      }}
                    >
                      Expert
                    </NavLink>
                  </NavItem>
                </Nav>

                <p className="description">
                  Providing Flexible Plans for the customer to Pick
                </p>
                <div className="description" style={{marginTop: "30px"}}>
                  Our Private Residential Proxies Do works on Following Sites :
                  <p>- Shopify</p>
                  <p>- Supreme</p>
                  <p>- Footlocker EU</p>
                  <p>- Footsites</p>
                  <p>- Mesh Site : Footpatrol, Size?, JDSporls, TheHipstore</p>
                  <p>- Nike SNKRS (All Regions)</p>
                  <p>- Adidas (All Regions)</p>
                  <p>- Solebox</p>
                  <p>- YeezySupply</p>
                  <p>- And Much More</p>
                </div>
              </Col>

              <Col className="ml-auto mr-auto" md="7">
                <TabContent
                  className="tab-space"
                  activeTab={"thirdTabs" + this.state.thirdTabs}
                >
                  <TabPane tabId="thirdTabs1">
                    <PlanList plans={plans.filter(plan => !plan.isExpertPlan || plan.isExpertPlan === false)} isActivePlan={isActivePlan} openCheckoutModal={this.openCheckoutModal} />
                  </TabPane>
                  <TabPane tabId="thirdTabs2">
                    <PlanList plans={plans.filter(plan => plan.isExpertPlan && plan.isExpertPlan === true)} isActivePlan={isActivePlan} openCheckoutModal={this.openCheckoutModal} />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
        {checkoutModals.map(checkoutModal => (
          checkoutModal
        ))}
      </div>
    );
  }
}

const PlanList = ({ plans, isActivePlan, openCheckoutModal }) => (
  <Row>
    {plans.map(plan => (
      <Col md="4" key={plan.planID}>
        <Card className="card-pricing card-plain">
          <CardBody>
            <h6 className="category">{plan.name}</h6>
            <CardTitle tag="h1">
              <small>$</small>
              {plan.price}
            </CardTitle>
            <ul>
              <li>
                <b>{plan.dataLimitMB/1000} GB</b> Data
              </li>
              <li>
                <b>140+</b> Locations Supported
              </li>
              <li>
                <b>{plan.validityHours/24}</b> Days Expiration Time
              </li>
              <li>
                <b>Unlimited </b> Proxy Generation
              </li>
              <li>
                <b>ASN </b> Level targetting
              </li>
              <li>
                <b>Static & Rotating</b> Proxies
              </li>
              <li>
                <b>Fully Dedicated</b>
              </li>
              <li>
                Average Speeds: <b>1500MS-5000MS</b>
              </li>
            </ul>
            <Button
              data-planid={plan.planID}
              disabled={!isActivePlan || !plan.active}
              className="btn-round"
              color="primary"
              onClick={openCheckoutModal}
            >
              {(isActivePlan && plan.active) ? 'Buy Now!' : 'Sold Out!'}
            </Button>
          </CardBody>
        </Card>
      </Col>
    ))}
  </Row>
);

export default Pricing;
