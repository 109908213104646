/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Media,
  Row,
  Col
} from "reactstrap";
// core components
import TimeChart from "../../variables/TimeChart.js";
import BotRentalContent from "./BotRentalContent.js";


class BotRental extends React.Component {
  state = {
    paymentTabs: 1,
  };
  componentDidMount() {
    document.body.classList.add("checkout-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("checkout-page");
  }
  render() {
    return (
      <>
        <div className="section">
          <Row>
            <Col lg="4">
              <h2 className="title">Plan summary</h2>
              <Card>
                <CardBody>
                  <Media className="align-items-center">
                    <h3 className="h6 text-secondary mr-3">Bot</h3>
                    <Media body className="text-right">
                      <span>-</span>
                    </Media>
                  </Media>
                  <hr className="line-info mb-4" />

                  <TimeChart />
                  <hr className="line-info mb-3" />
                  <Media className="align-items-center">
                    <h3 className="h6 text-secondary mr-3">Status</h3>
                    <Media body className="text-right">
                      <span>-</span>
                    </Media>
                  </Media>
                  <hr className="line-info mb-3" />
                    <label>License Key</label>
                  <hr className="line-info mb-1" />
                  <Media className="align-items-center">

                    <Media body className="text-center">
                      <Button disabled className="btn-round btn-icon" style={{marginRight: '10px'}} size="lg" color="info">
                        <i className="tim-icons icon-single-copy-04" />
                      </Button>

                      <Button disabled className="btn-round btn-icon" style={{marginLeft: '10px'}} size="lg" color="info">
                        <i className="tim-icons icon-cloud-download-93" />
                      </Button>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col lg="8">
              <Form className="js-validate">
                <h2 className="title">Bot Rental</h2>
                <BotRentalContent />
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default BotRental;
