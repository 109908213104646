import React from "react";
// reactstrap components
import {
  Container
} from "reactstrap";
// core components
import Projects from "./DashboardSections/Projects.js";
import { withAuthorization } from '../components/Session';


const INITIAL_STATE = {
  paymentTabs: 1
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index
    });
  };

  componentDidMount() {
    document.body.classList.add("index-page");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
  }

  componentWillUnmount() {
    document.body.classList.remove("index-page");
  }

  render() {
    return (
      <div className="wrapper" ref="wrapper">
        <img
          alt="..."
          className="path"
          src={require("assets/img/blob.png")}
        />
        <Container>
          <div className="section">
            <Projects></Projects>
          </div>
        </Container>
      </div>
    );
  }
}

const condition = authUser => authUser != null;
export default withAuthorization(condition)(Dashboard);
