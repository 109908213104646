/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";


class Headers extends React.Component {
  state = {};
  render() {
    return (
      <>
          {/* ********* HEADER 1 ********* */}
          <div className="header header-1">
            <div className="page-header header-filter">
              <div className="page-header-image"/>
              <Container>
                <Row className="align-items-center">
                  <Col className="mr-auto text-left mt-5">
                    <h1 className="title">
                      One Clicks Captcha Account
                    </h1>
                    <h4>Necessary tools to avoid captcha appears during checkout.</h4>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
      </>
    );
  }
}

export default Headers;
