import React from "react";
import toastr from 'toastr';

/*
  Functional component that render page loading with custom css
 */
const PageLoader = () => {
  return ( <
    div id = "page-preloader" >
    <
    div className = "loader-ring" > < /div> <
    div className = "loader-ring2" > < /div> < /
    div >
  );
}

/*
  Function that show toastr based on toastType(success,error,info)
 */
const showToastAlert = (toastType, toastMessage) => {
  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: true,
    progressBar: true,
    positionClass: 'toast-top-right',
    preventDuplicates: false,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
  }

  if (toastType.toLowerCase() === 'success') {
    toastr.success(toastMessage, 'Success')
  } else if (toastType.toLowerCase() === 'info') {
    toastr.info(toastMessage, 'Info')
  } else {
    toastr.error(toastMessage, 'Error')
  }
}

/**
 * [Function that generate Random String of param length]
 */
function generateRandomString(length) {
  let string = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    string += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return string;
}

/*
  Function that return date in MMDDYYYY for given date selerator
 */
const getDate = (date = new Date(), seperator = "/") => {
  let year = String(date.getFullYear());
  let month = String(date.getMonth() + 1);
  month = month.length === 1 ? `0${month}` : month;
  let day = String(date.getDate());
  day = day.length === 1 ? `0${day}` : day;
  return `${month}${seperator}${day}${seperator}${year}`;
}

/*
  Function that copy passed data
 */
const copyData = (data) => {
  let textarea = document.createElement('textarea');
  textarea.value = data
  document.body.appendChild(textarea);
  textarea.select();
  try {
    let successful = document.execCommand('copy');
    document.body.removeChild(textarea);
    if (successful) {
      showToastAlert("Success", "Proxy copied successfully");
    } else {
      showToastAlert('Error', "Copy functionality isn't working in current browser, Please use Download option");
    }
  } catch (err) {
    showToastAlert('Error', "Copy functionality isn't working in current browser, Please use Download option");
  }
}

/*
  Function that copy passed data
 */
const downloadFile = (fileName, fileData) => {
  let data = new Blob([fileData], {
    type: 'text/plain'
  });
  let file = window.URL.createObjectURL(data);
  let link = document.createElement('a');
  link.setAttribute('href', file);
  link.setAttribute('download', fileName);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export {
  PageLoader,
  showToastAlert,
  generateRandomString,
  getDate,
  copyData,
  downloadFile
};
